import "./NotFoundPage.css"

const NotFoundPage = () => {
  return (
      <div className="NotFoundPage">
        <p>404 not found</p>
      </div>
  );
};
export default NotFoundPage;
