/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PronounsInputValueInput } from './PronounsInputValueInput';
import {
    PronounsInputValueInputFromJSON,
    PronounsInputValueInputFromJSONTyped,
    PronounsInputValueInputToJSON,
} from './PronounsInputValueInput';

/**
 * 
 * @export
 * @interface SetPronounsInput
 */
export interface SetPronounsInput {
    /**
     * 
     * @type {Array<PronounsInputValueInput>}
     * @memberof SetPronounsInput
     */
    pronouns: Array<PronounsInputValueInput>;
}

/**
 * Check if a given object implements the SetPronounsInput interface.
 */
export function instanceOfSetPronounsInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pronouns" in value;

    return isInstance;
}

export function SetPronounsInputFromJSON(json: any): SetPronounsInput {
    return SetPronounsInputFromJSONTyped(json, false);
}

export function SetPronounsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPronounsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pronouns': ((json['pronouns'] as Array<any>).map(PronounsInputValueInputFromJSON)),
    };
}

export function SetPronounsInputToJSON(value?: SetPronounsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pronouns': ((value.pronouns as Array<any>).map(PronounsInputValueInputToJSON)),
    };
}

