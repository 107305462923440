/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PronounsOutputValueOutput
 */
export interface PronounsOutputValueOutput {
    /**
     * 
     * @type {string}
     * @memberof PronounsOutputValueOutput
     */
    type: PronounsOutputValueOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PronounsOutputValueOutput
     */
    otherText?: string;
}


/**
 * @export
 */
export const PronounsOutputValueOutputTypeEnum = {
    SheHer: 'SHE_HER',
    TheyThem: 'THEY_THEM',
    HeHim: 'HE_HIM',
    Other: 'OTHER'
} as const;
export type PronounsOutputValueOutputTypeEnum = typeof PronounsOutputValueOutputTypeEnum[keyof typeof PronounsOutputValueOutputTypeEnum];


/**
 * Check if a given object implements the PronounsOutputValueOutput interface.
 */
export function instanceOfPronounsOutputValueOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PronounsOutputValueOutputFromJSON(json: any): PronounsOutputValueOutput {
    return PronounsOutputValueOutputFromJSONTyped(json, false);
}

export function PronounsOutputValueOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PronounsOutputValueOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'otherText': !exists(json, 'otherText') ? undefined : json['otherText'],
    };
}

export function PronounsOutputValueOutputToJSON(value?: PronounsOutputValueOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'otherText': value.otherText,
    };
}

