/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatingAppInputValueInput } from './DatingAppInputValueInput';
import {
    DatingAppInputValueInputFromJSON,
    DatingAppInputValueInputFromJSONTyped,
    DatingAppInputValueInputToJSON,
} from './DatingAppInputValueInput';

/**
 * 
 * @export
 * @interface SetDatingAppsInput
 */
export interface SetDatingAppsInput {
    /**
     * 
     * @type {Array<DatingAppInputValueInput>}
     * @memberof SetDatingAppsInput
     */
    apps: Array<DatingAppInputValueInput>;
}

/**
 * Check if a given object implements the SetDatingAppsInput interface.
 */
export function instanceOfSetDatingAppsInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apps" in value;

    return isInstance;
}

export function SetDatingAppsInputFromJSON(json: any): SetDatingAppsInput {
    return SetDatingAppsInputFromJSONTyped(json, false);
}

export function SetDatingAppsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDatingAppsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apps': ((json['apps'] as Array<any>).map(DatingAppInputValueInputFromJSON)),
    };
}

export function SetDatingAppsInputToJSON(value?: SetDatingAppsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apps': ((value.apps as Array<any>).map(DatingAppInputValueInputToJSON)),
    };
}

