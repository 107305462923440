/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetProfileByIdOutput,
  PostProfileInput,
  PostProfileOutput,
  ProfileOutput,
  SetEmailAddressInput,
  SetEmailAddressOutput,
  SetFullNameInput,
  SetFullNameOutput,
  SetPhotoInput,
  SkipProfileOutput,
  UpdateProfileInput,
  UpdateSettingsInput,
  VerifyEmailCodeInput,
} from '../models';
import {
    GetProfileByIdOutputFromJSON,
    GetProfileByIdOutputToJSON,
    PostProfileInputFromJSON,
    PostProfileInputToJSON,
    PostProfileOutputFromJSON,
    PostProfileOutputToJSON,
    ProfileOutputFromJSON,
    ProfileOutputToJSON,
    SetEmailAddressInputFromJSON,
    SetEmailAddressInputToJSON,
    SetEmailAddressOutputFromJSON,
    SetEmailAddressOutputToJSON,
    SetFullNameInputFromJSON,
    SetFullNameInputToJSON,
    SetFullNameOutputFromJSON,
    SetFullNameOutputToJSON,
    SetPhotoInputFromJSON,
    SetPhotoInputToJSON,
    SkipProfileOutputFromJSON,
    SkipProfileOutputToJSON,
    UpdateProfileInputFromJSON,
    UpdateProfileInputToJSON,
    UpdateSettingsInputFromJSON,
    UpdateSettingsInputToJSON,
    VerifyEmailCodeInputFromJSON,
    VerifyEmailCodeInputToJSON,
} from '../models';

export interface GetProfileByIdRequest {
    id: string;
}

export interface PostProfileRequest {
    postProfileInput: PostProfileInput;
}

export interface SetEmailAddressRequest {
    setEmailAddressInput: SetEmailAddressInput;
}

export interface SetFullNameRequest {
    setFullNameInput: SetFullNameInput;
}

export interface SetPhotoRequest {
    setPhotoInput: SetPhotoInput;
}

export interface UpdateProfileRequest {
    updateProfileInput: UpdateProfileInput;
}

export interface UpdateSettingsRequest {
    updateSettingsInput: UpdateSettingsInput;
}

export interface VerifyEmailCodeRequest {
    verifyEmailCodeInput: VerifyEmailCodeInput;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async deleteProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProfileRaw(initOverrides);
    }

    /**
     */
    async getProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileOutputFromJSON(jsonValue));
    }

    /**
     */
    async getProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileOutput> {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getProfileByIdRaw(requestParameters: GetProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProfileByIdOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProfileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileByIdOutputFromJSON(jsonValue));
    }

    /**
     */
    async getProfileById(requestParameters: GetProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProfileByIdOutput> {
        const response = await this.getProfileByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postProfileRaw(requestParameters: PostProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostProfileOutput>> {
        if (requestParameters.postProfileInput === null || requestParameters.postProfileInput === undefined) {
            throw new runtime.RequiredError('postProfileInput','Required parameter requestParameters.postProfileInput was null or undefined when calling postProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostProfileInputToJSON(requestParameters.postProfileInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostProfileOutputFromJSON(jsonValue));
    }

    /**
     */
    async postProfile(requestParameters: PostProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostProfileOutput> {
        const response = await this.postProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setEmailAddressRaw(requestParameters: SetEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetEmailAddressOutput>> {
        if (requestParameters.setEmailAddressInput === null || requestParameters.setEmailAddressInput === undefined) {
            throw new runtime.RequiredError('setEmailAddressInput','Required parameter requestParameters.setEmailAddressInput was null or undefined when calling setEmailAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/set-email-address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmailAddressInputToJSON(requestParameters.setEmailAddressInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetEmailAddressOutputFromJSON(jsonValue));
    }

    /**
     */
    async setEmailAddress(requestParameters: SetEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetEmailAddressOutput> {
        const response = await this.setEmailAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setFullNameRaw(requestParameters: SetFullNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetFullNameOutput>> {
        if (requestParameters.setFullNameInput === null || requestParameters.setFullNameInput === undefined) {
            throw new runtime.RequiredError('setFullNameInput','Required parameter requestParameters.setFullNameInput was null or undefined when calling setFullName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/set-full-name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetFullNameInputToJSON(requestParameters.setFullNameInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetFullNameOutputFromJSON(jsonValue));
    }

    /**
     */
    async setFullName(requestParameters: SetFullNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetFullNameOutput> {
        const response = await this.setFullNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPhotoRaw(requestParameters: SetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setPhotoInput === null || requestParameters.setPhotoInput === undefined) {
            throw new runtime.RequiredError('setPhotoInput','Required parameter requestParameters.setPhotoInput was null or undefined when calling setPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/set-photo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPhotoInputToJSON(requestParameters.setPhotoInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setPhoto(requestParameters: SetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPhotoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async skipProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SkipProfileOutput>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/skip-profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SkipProfileOutputFromJSON(jsonValue));
    }

    /**
     */
    async skipProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SkipProfileOutput> {
        const response = await this.skipProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProfileRaw(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateProfileInput === null || requestParameters.updateProfileInput === undefined) {
            throw new runtime.RequiredError('updateProfileInput','Required parameter requestParameters.updateProfileInput was null or undefined when calling updateProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileInputToJSON(requestParameters.updateProfileInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateProfile(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProfileRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateSettingsInput === null || requestParameters.updateSettingsInput === undefined) {
            throw new runtime.RequiredError('updateSettingsInput','Required parameter requestParameters.updateSettingsInput was null or undefined when calling updateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt_auth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/update-settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSettingsInputToJSON(requestParameters.updateSettingsInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateSettings(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSettingsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async verifyEmailCodeRaw(requestParameters: VerifyEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verifyEmailCodeInput === null || requestParameters.verifyEmailCodeInput === undefined) {
            throw new runtime.RequiredError('verifyEmailCodeInput','Required parameter requestParameters.verifyEmailCodeInput was null or undefined when calling verifyEmailCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/profile/verify-email-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailCodeInputToJSON(requestParameters.verifyEmailCodeInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifyEmailCode(requestParameters: VerifyEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyEmailCodeRaw(requestParameters, initOverrides);
    }

}
