/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatingAppOutputValueOutput
 */
export interface DatingAppOutputValueOutput {
    /**
     * 
     * @type {string}
     * @memberof DatingAppOutputValueOutput
     */
    appId?: string;
    /**
     * 
     * @type {string}
     * @memberof DatingAppOutputValueOutput
     */
    otherAppName?: string;
}

/**
 * Check if a given object implements the DatingAppOutputValueOutput interface.
 */
export function instanceOfDatingAppOutputValueOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DatingAppOutputValueOutputFromJSON(json: any): DatingAppOutputValueOutput {
    return DatingAppOutputValueOutputFromJSONTyped(json, false);
}

export function DatingAppOutputValueOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatingAppOutputValueOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appId': !exists(json, 'appId') ? undefined : json['appId'],
        'otherAppName': !exists(json, 'otherAppName') ? undefined : json['otherAppName'],
    };
}

export function DatingAppOutputValueOutputToJSON(value?: DatingAppOutputValueOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appId': value.appId,
        'otherAppName': value.otherAppName,
    };
}

