/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SkipProfileOutput
 */
export interface SkipProfileOutput {
    /**
     * 
     * @type {string}
     * @memberof SkipProfileOutput
     */
    token: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SkipProfileOutput
     */
    missingFields: Array<SkipProfileOutputMissingFieldsEnum>;
}


/**
 * @export
 */
export const SkipProfileOutputMissingFieldsEnum = {
    EmailAddress: 'EMAIL_ADDRESS',
    FullName: 'FULL_NAME',
    Profile: 'PROFILE'
} as const;
export type SkipProfileOutputMissingFieldsEnum = typeof SkipProfileOutputMissingFieldsEnum[keyof typeof SkipProfileOutputMissingFieldsEnum];


/**
 * Check if a given object implements the SkipProfileOutput interface.
 */
export function instanceOfSkipProfileOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "missingFields" in value;

    return isInstance;
}

export function SkipProfileOutputFromJSON(json: any): SkipProfileOutput {
    return SkipProfileOutputFromJSONTyped(json, false);
}

export function SkipProfileOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkipProfileOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'missingFields': json['missingFields'],
    };
}

export function SkipProfileOutputToJSON(value?: SkipProfileOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'missingFields': value.missingFields,
    };
}

