import Logo from "./images/logo-hearts-small.png";
import "./Header.css";

function Header() {
  return (
      <div className="Header">
        <img className="Logo" src={Logo} alt="Clara logo" />
      </div>
  )
}

export default Header;
