/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileOutput } from './ProfileOutput';
import {
    ProfileOutputFromJSON,
    ProfileOutputFromJSONTyped,
    ProfileOutputToJSON,
} from './ProfileOutput';

/**
 * 
 * @export
 * @interface GetProfileByIdOutput
 */
export interface GetProfileByIdOutput {
    /**
     * 
     * @type {ProfileOutput}
     * @memberof GetProfileByIdOutput
     */
    profile: ProfileOutput;
}

/**
 * Check if a given object implements the GetProfileByIdOutput interface.
 */
export function instanceOfGetProfileByIdOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "profile" in value;

    return isInstance;
}

export function GetProfileByIdOutputFromJSON(json: any): GetProfileByIdOutput {
    return GetProfileByIdOutputFromJSONTyped(json, false);
}

export function GetProfileByIdOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProfileByIdOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': ProfileOutputFromJSON(json['profile']),
    };
}

export function GetProfileByIdOutputToJSON(value?: GetProfileByIdOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': ProfileOutputToJSON(value.profile),
    };
}

