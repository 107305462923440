/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetLocationInput
 */
export interface SetLocationInput {
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    addressLine4?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    cityTownLocality: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    countyDistrictSublocality?: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    stateProvinceRegion: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof SetLocationInput
     * @deprecated
     */
    accountId?: string;
}

/**
 * Check if a given object implements the SetLocationInput interface.
 */
export function instanceOfSetLocationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cityTownLocality" in value;
    isInstance = isInstance && "stateProvinceRegion" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function SetLocationInputFromJSON(json: any): SetLocationInput {
    return SetLocationInputFromJSONTyped(json, false);
}

export function SetLocationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetLocationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'addressLine3': !exists(json, 'addressLine3') ? undefined : json['addressLine3'],
        'addressLine4': !exists(json, 'addressLine4') ? undefined : json['addressLine4'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'cityTownLocality': json['cityTownLocality'],
        'countyDistrictSublocality': !exists(json, 'countyDistrictSublocality') ? undefined : json['countyDistrictSublocality'],
        'stateProvinceRegion': json['stateProvinceRegion'],
        'country': json['country'],
        'accountId': !exists(json, 'accountId') ? undefined : json['accountId'],
    };
}

export function SetLocationInputToJSON(value?: SetLocationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'addressLine3': value.addressLine3,
        'addressLine4': value.addressLine4,
        'postalCode': value.postalCode,
        'cityTownLocality': value.cityTownLocality,
        'countyDistrictSublocality': value.countyDistrictSublocality,
        'stateProvinceRegion': value.stateProvinceRegion,
        'country': value.country,
        'accountId': value.accountId,
    };
}

