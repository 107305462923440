/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetFullNameOutput
 */
export interface SetFullNameOutput {
    /**
     * 
     * @type {string}
     * @memberof SetFullNameOutput
     */
    token: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetFullNameOutput
     */
    missingFields: Array<SetFullNameOutputMissingFieldsEnum>;
}


/**
 * @export
 */
export const SetFullNameOutputMissingFieldsEnum = {
    EmailAddress: 'EMAIL_ADDRESS',
    FullName: 'FULL_NAME',
    Profile: 'PROFILE'
} as const;
export type SetFullNameOutputMissingFieldsEnum = typeof SetFullNameOutputMissingFieldsEnum[keyof typeof SetFullNameOutputMissingFieldsEnum];


/**
 * Check if a given object implements the SetFullNameOutput interface.
 */
export function instanceOfSetFullNameOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "missingFields" in value;

    return isInstance;
}

export function SetFullNameOutputFromJSON(json: any): SetFullNameOutput {
    return SetFullNameOutputFromJSONTyped(json, false);
}

export function SetFullNameOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetFullNameOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'missingFields': json['missingFields'],
    };
}

export function SetFullNameOutputToJSON(value?: SetFullNameOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'missingFields': value.missingFields,
    };
}

