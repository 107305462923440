/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  VerifyPhoneNumberInput,
  VerifySmsCodeInput,
  VerifySmsCodeOutput,
} from '../models';
import {
    VerifyPhoneNumberInputFromJSON,
    VerifyPhoneNumberInputToJSON,
    VerifySmsCodeInputFromJSON,
    VerifySmsCodeInputToJSON,
    VerifySmsCodeOutputFromJSON,
    VerifySmsCodeOutputToJSON,
} from '../models';

export interface VerifyPhoneNumberRequest {
    verifyPhoneNumberInput: VerifyPhoneNumberInput;
}

export interface VerifySmsCodeRequest {
    verifySmsCodeInput: VerifySmsCodeInput;
}

/**
 * 
 */
export class AuthorizationApi extends runtime.BaseAPI {

    /**
     */
    async verifyPhoneNumberRaw(requestParameters: VerifyPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.verifyPhoneNumberInput === null || requestParameters.verifyPhoneNumberInput === undefined) {
            throw new runtime.RequiredError('verifyPhoneNumberInput','Required parameter requestParameters.verifyPhoneNumberInput was null or undefined when calling verifyPhoneNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/verify-phone-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyPhoneNumberInputToJSON(requestParameters.verifyPhoneNumberInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifyPhoneNumber(requestParameters: VerifyPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyPhoneNumberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async verifySmsCodeRaw(requestParameters: VerifySmsCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerifySmsCodeOutput>> {
        if (requestParameters.verifySmsCodeInput === null || requestParameters.verifySmsCodeInput === undefined) {
            throw new runtime.RequiredError('verifySmsCodeInput','Required parameter requestParameters.verifySmsCodeInput was null or undefined when calling verifySmsCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/verify-sms-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifySmsCodeInputToJSON(requestParameters.verifySmsCodeInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerifySmsCodeOutputFromJSON(jsonValue));
    }

    /**
     */
    async verifySmsCode(requestParameters: VerifySmsCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerifySmsCodeOutput> {
        const response = await this.verifySmsCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
