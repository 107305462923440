/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSettingsInput
 */
export interface UpdateSettingsInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingsInput
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSettingsInput
     */
    shouldSendUpdates: boolean;
}

/**
 * Check if a given object implements the UpdateSettingsInput interface.
 */
export function instanceOfUpdateSettingsInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shouldSendUpdates" in value;

    return isInstance;
}

export function UpdateSettingsInputFromJSON(json: any): UpdateSettingsInput {
    return UpdateSettingsInputFromJSONTyped(json, false);
}

export function UpdateSettingsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSettingsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'shouldSendUpdates': json['shouldSendUpdates'],
    };
}

export function UpdateSettingsInputToJSON(value?: UpdateSettingsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'shouldSendUpdates': value.shouldSendUpdates,
    };
}

