/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SetDatingAppsInput } from './SetDatingAppsInput';
import {
    SetDatingAppsInputFromJSON,
    SetDatingAppsInputFromJSONTyped,
    SetDatingAppsInputToJSON,
} from './SetDatingAppsInput';
import type { SetGenderInput } from './SetGenderInput';
import {
    SetGenderInputFromJSON,
    SetGenderInputFromJSONTyped,
    SetGenderInputToJSON,
} from './SetGenderInput';
import type { SetLocationInput } from './SetLocationInput';
import {
    SetLocationInputFromJSON,
    SetLocationInputFromJSONTyped,
    SetLocationInputToJSON,
} from './SetLocationInput';
import type { SetPronounsInput } from './SetPronounsInput';
import {
    SetPronounsInputFromJSON,
    SetPronounsInputFromJSONTyped,
    SetPronounsInputToJSON,
} from './SetPronounsInput';

/**
 * 
 * @export
 * @interface PostProfileInput
 */
export interface PostProfileInput {
    /**
     * 
     * @type {string}
     * @memberof PostProfileInput
     */
    dateOfBirth: string;
    /**
     * 
     * @type {SetLocationInput}
     * @memberof PostProfileInput
     */
    locationInput: SetLocationInput;
    /**
     * 
     * @type {SetGenderInput}
     * @memberof PostProfileInput
     */
    genderInput: SetGenderInput;
    /**
     * 
     * @type {SetPronounsInput}
     * @memberof PostProfileInput
     */
    pronounsInput: SetPronounsInput;
    /**
     * 
     * @type {string}
     * @memberof PostProfileInput
     */
    datingPreference: PostProfileInputDatingPreferenceEnum;
    /**
     * 
     * @type {SetDatingAppsInput}
     * @memberof PostProfileInput
     */
    datingAppsInput: SetDatingAppsInput;
    /**
     * 
     * @type {string}
     * @memberof PostProfileInput
     */
    photoId: string;
}


/**
 * @export
 */
export const PostProfileInputDatingPreferenceEnum = {
    Men: 'MEN',
    Women: 'WOMEN',
    Everyone: 'EVERYONE'
} as const;
export type PostProfileInputDatingPreferenceEnum = typeof PostProfileInputDatingPreferenceEnum[keyof typeof PostProfileInputDatingPreferenceEnum];


/**
 * Check if a given object implements the PostProfileInput interface.
 */
export function instanceOfPostProfileInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "locationInput" in value;
    isInstance = isInstance && "genderInput" in value;
    isInstance = isInstance && "pronounsInput" in value;
    isInstance = isInstance && "datingPreference" in value;
    isInstance = isInstance && "datingAppsInput" in value;
    isInstance = isInstance && "photoId" in value;

    return isInstance;
}

export function PostProfileInputFromJSON(json: any): PostProfileInput {
    return PostProfileInputFromJSONTyped(json, false);
}

export function PostProfileInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostProfileInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfBirth': json['dateOfBirth'],
        'locationInput': SetLocationInputFromJSON(json['locationInput']),
        'genderInput': SetGenderInputFromJSON(json['genderInput']),
        'pronounsInput': SetPronounsInputFromJSON(json['pronounsInput']),
        'datingPreference': json['datingPreference'],
        'datingAppsInput': SetDatingAppsInputFromJSON(json['datingAppsInput']),
        'photoId': json['photoId'],
    };
}

export function PostProfileInputToJSON(value?: PostProfileInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateOfBirth': value.dateOfBirth,
        'locationInput': SetLocationInputToJSON(value.locationInput),
        'genderInput': SetGenderInputToJSON(value.genderInput),
        'pronounsInput': SetPronounsInputToJSON(value.pronounsInput),
        'datingPreference': value.datingPreference,
        'datingAppsInput': SetDatingAppsInputToJSON(value.datingAppsInput),
        'photoId': value.photoId,
    };
}

