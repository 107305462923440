/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatingAppOutputValueOutput } from './DatingAppOutputValueOutput';
import {
    DatingAppOutputValueOutputFromJSON,
    DatingAppOutputValueOutputFromJSONTyped,
    DatingAppOutputValueOutputToJSON,
} from './DatingAppOutputValueOutput';
import type { GenderOutput } from './GenderOutput';
import {
    GenderOutputFromJSON,
    GenderOutputFromJSONTyped,
    GenderOutputToJSON,
} from './GenderOutput';
import type { LocationOutput } from './LocationOutput';
import {
    LocationOutputFromJSON,
    LocationOutputFromJSONTyped,
    LocationOutputToJSON,
} from './LocationOutput';
import type { PronounsOutputValueOutput } from './PronounsOutputValueOutput';
import {
    PronounsOutputValueOutputFromJSON,
    PronounsOutputValueOutputFromJSONTyped,
    PronounsOutputValueOutputToJSON,
} from './PronounsOutputValueOutput';

/**
 * 
 * @export
 * @interface ProfileOutput
 */
export interface ProfileOutput {
    /**
     * 
     * @type {string}
     * @memberof ProfileOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOutput
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileOutput
     */
    fullName: string;
    /**
     * 
     * @type {Date}
     * @memberof ProfileOutput
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {LocationOutput}
     * @memberof ProfileOutput
     */
    location?: LocationOutput;
    /**
     * 
     * @type {GenderOutput}
     * @memberof ProfileOutput
     */
    genderOutput?: GenderOutput;
    /**
     * 
     * @type {Array<PronounsOutputValueOutput>}
     * @memberof ProfileOutput
     */
    pronouns?: Array<PronounsOutputValueOutput>;
    /**
     * 
     * @type {string}
     * @memberof ProfileOutput
     */
    datingPreferences?: ProfileOutputDatingPreferencesEnum;
    /**
     * 
     * @type {Array<DatingAppOutputValueOutput>}
     * @memberof ProfileOutput
     */
    datingApps?: Array<DatingAppOutputValueOutput>;
    /**
     * 
     * @type {string}
     * @memberof ProfileOutput
     */
    photoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOutput
     */
    shouldSendUpdates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileOutput
     */
    isEmailVerified: boolean;
}


/**
 * @export
 */
export const ProfileOutputDatingPreferencesEnum = {
    Men: 'MEN',
    Women: 'WOMEN',
    Everyone: 'EVERYONE'
} as const;
export type ProfileOutputDatingPreferencesEnum = typeof ProfileOutputDatingPreferencesEnum[keyof typeof ProfileOutputDatingPreferencesEnum];


/**
 * Check if a given object implements the ProfileOutput interface.
 */
export function instanceOfProfileOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "emailAddress" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "shouldSendUpdates" in value;
    isInstance = isInstance && "isEmailVerified" in value;

    return isInstance;
}

export function ProfileOutputFromJSON(json: any): ProfileOutput {
    return ProfileOutputFromJSONTyped(json, false);
}

export function ProfileOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'emailAddress': json['emailAddress'],
        'fullName': json['fullName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'location': !exists(json, 'location') ? undefined : LocationOutputFromJSON(json['location']),
        'genderOutput': !exists(json, 'genderOutput') ? undefined : GenderOutputFromJSON(json['genderOutput']),
        'pronouns': !exists(json, 'pronouns') ? undefined : ((json['pronouns'] as Array<any>).map(PronounsOutputValueOutputFromJSON)),
        'datingPreferences': !exists(json, 'datingPreferences') ? undefined : json['datingPreferences'],
        'datingApps': !exists(json, 'datingApps') ? undefined : ((json['datingApps'] as Array<any>).map(DatingAppOutputValueOutputFromJSON)),
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
        'shouldSendUpdates': json['shouldSendUpdates'],
        'isEmailVerified': json['isEmailVerified'],
    };
}

export function ProfileOutputToJSON(value?: ProfileOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'emailAddress': value.emailAddress,
        'fullName': value.fullName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'location': LocationOutputToJSON(value.location),
        'genderOutput': GenderOutputToJSON(value.genderOutput),
        'pronouns': value.pronouns === undefined ? undefined : ((value.pronouns as Array<any>).map(PronounsOutputValueOutputToJSON)),
        'datingPreferences': value.datingPreferences,
        'datingApps': value.datingApps === undefined ? undefined : ((value.datingApps as Array<any>).map(DatingAppOutputValueOutputToJSON)),
        'photoUrl': value.photoUrl,
        'shouldSendUpdates': value.shouldSendUpdates,
        'isEmailVerified': value.isEmailVerified,
    };
}

