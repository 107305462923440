import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { profileApi } from "./ApiConfig";
import "./EmailVerificationPage.css";

enum Status {
  CONFIRMING, CONFIRMED, ERROR
}

const EmailVerificationPage = () => {
  const [status, setStatus] = useState(Status.CONFIRMING);
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    async function fetchData() {
      if (!code) {
        return;
      }
      try {
        await profileApi.verifyEmailCode({
          verifyEmailCodeInput: { code },
        });
        setStatus(Status.CONFIRMED);
      } catch (e) {
        console.error(e);
        setStatus(Status.ERROR);
      }
    }
    fetchData();
  }, [code]);

  return (
      <div className="EmailVerificationPage">
          {status === Status.CONFIRMING &&
              <p className="confirming">
                Confirming your email...
              </p>
          }
          {status === Status.CONFIRMED &&
              <>
              <p className="confirmed">
                Your email has been confirmed!
              </p>
              <p className="return">
                Return to the Clara app to get started.
              </p>
              </>
          }
          {status === Status.ERROR &&
              <p className="error">
                Your confirmation link has expired.
              </p>
          }
      </div>
  );
};

export default EmailVerificationPage;
