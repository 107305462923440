/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetEmailAddressOutput
 */
export interface SetEmailAddressOutput {
    /**
     * 
     * @type {string}
     * @memberof SetEmailAddressOutput
     */
    token: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetEmailAddressOutput
     */
    missingFields: Array<SetEmailAddressOutputMissingFieldsEnum>;
}


/**
 * @export
 */
export const SetEmailAddressOutputMissingFieldsEnum = {
    EmailAddress: 'EMAIL_ADDRESS',
    FullName: 'FULL_NAME',
    Profile: 'PROFILE'
} as const;
export type SetEmailAddressOutputMissingFieldsEnum = typeof SetEmailAddressOutputMissingFieldsEnum[keyof typeof SetEmailAddressOutputMissingFieldsEnum];


/**
 * Check if a given object implements the SetEmailAddressOutput interface.
 */
export function instanceOfSetEmailAddressOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "missingFields" in value;

    return isInstance;
}

export function SetEmailAddressOutputFromJSON(json: any): SetEmailAddressOutput {
    return SetEmailAddressOutputFromJSONTyped(json, false);
}

export function SetEmailAddressOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetEmailAddressOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'missingFields': json['missingFields'],
    };
}

export function SetEmailAddressOutputToJSON(value?: SetEmailAddressOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'missingFields': value.missingFields,
    };
}

