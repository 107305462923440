/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenderOutput
 */
export interface GenderOutput {
    /**
     * 
     * @type {string}
     * @memberof GenderOutput
     */
    gender: GenderOutputGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof GenderOutput
     */
    otherText?: string;
}


/**
 * @export
 */
export const GenderOutputGenderEnum = {
    Woman: 'WOMAN',
    Man: 'MAN',
    NonBinary: 'NON_BINARY',
    Other: 'OTHER'
} as const;
export type GenderOutputGenderEnum = typeof GenderOutputGenderEnum[keyof typeof GenderOutputGenderEnum];


/**
 * Check if a given object implements the GenderOutput interface.
 */
export function instanceOfGenderOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gender" in value;

    return isInstance;
}

export function GenderOutputFromJSON(json: any): GenderOutput {
    return GenderOutputFromJSONTyped(json, false);
}

export function GenderOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gender': json['gender'],
        'otherText': !exists(json, 'otherText') ? undefined : json['otherText'],
    };
}

export function GenderOutputToJSON(value?: GenderOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gender': value.gender,
        'otherText': value.otherText,
    };
}

