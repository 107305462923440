/* tslint:disable */
/* eslint-disable */
/**
 * Clara API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SetDatingAppsInput } from './SetDatingAppsInput';
import {
    SetDatingAppsInputFromJSON,
    SetDatingAppsInputFromJSONTyped,
    SetDatingAppsInputToJSON,
} from './SetDatingAppsInput';
import type { SetGenderInput } from './SetGenderInput';
import {
    SetGenderInputFromJSON,
    SetGenderInputFromJSONTyped,
    SetGenderInputToJSON,
} from './SetGenderInput';
import type { SetLocationInput } from './SetLocationInput';
import {
    SetLocationInputFromJSON,
    SetLocationInputFromJSONTyped,
    SetLocationInputToJSON,
} from './SetLocationInput';
import type { SetPronounsInput } from './SetPronounsInput';
import {
    SetPronounsInputFromJSON,
    SetPronounsInputFromJSONTyped,
    SetPronounsInputToJSON,
} from './SetPronounsInput';

/**
 * 
 * @export
 * @interface UpdateProfileInput
 */
export interface UpdateProfileInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileInput
     */
    dateOfBirth: string;
    /**
     * 
     * @type {SetLocationInput}
     * @memberof UpdateProfileInput
     */
    locationInput?: SetLocationInput;
    /**
     * 
     * @type {SetGenderInput}
     * @memberof UpdateProfileInput
     */
    genderInput: SetGenderInput;
    /**
     * 
     * @type {SetPronounsInput}
     * @memberof UpdateProfileInput
     */
    pronounsInput?: SetPronounsInput;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileInput
     */
    datingPreference: UpdateProfileInputDatingPreferenceEnum;
    /**
     * 
     * @type {SetDatingAppsInput}
     * @memberof UpdateProfileInput
     */
    datingAppsInput?: SetDatingAppsInput;
}


/**
 * @export
 */
export const UpdateProfileInputDatingPreferenceEnum = {
    Men: 'MEN',
    Women: 'WOMEN',
    Everyone: 'EVERYONE'
} as const;
export type UpdateProfileInputDatingPreferenceEnum = typeof UpdateProfileInputDatingPreferenceEnum[keyof typeof UpdateProfileInputDatingPreferenceEnum];


/**
 * Check if a given object implements the UpdateProfileInput interface.
 */
export function instanceOfUpdateProfileInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "genderInput" in value;
    isInstance = isInstance && "datingPreference" in value;

    return isInstance;
}

export function UpdateProfileInputFromJSON(json: any): UpdateProfileInput {
    return UpdateProfileInputFromJSONTyped(json, false);
}

export function UpdateProfileInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfBirth': json['dateOfBirth'],
        'locationInput': !exists(json, 'locationInput') ? undefined : SetLocationInputFromJSON(json['locationInput']),
        'genderInput': SetGenderInputFromJSON(json['genderInput']),
        'pronounsInput': !exists(json, 'pronounsInput') ? undefined : SetPronounsInputFromJSON(json['pronounsInput']),
        'datingPreference': json['datingPreference'],
        'datingAppsInput': !exists(json, 'datingAppsInput') ? undefined : SetDatingAppsInputFromJSON(json['datingAppsInput']),
    };
}

export function UpdateProfileInputToJSON(value?: UpdateProfileInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateOfBirth': value.dateOfBirth,
        'locationInput': SetLocationInputToJSON(value.locationInput),
        'genderInput': SetGenderInputToJSON(value.genderInput),
        'pronounsInput': SetPronounsInputToJSON(value.pronounsInput),
        'datingPreference': value.datingPreference,
        'datingAppsInput': SetDatingAppsInputToJSON(value.datingAppsInput),
    };
}

